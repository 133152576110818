<template>
  <div class="order">
    <div class="_title" v-if="title">我的订单</div>
    <div class="_content" :class="className">
      <v-card flat class="mt-4" min-height="350">
        <v-card-text class="pa-0">
          <div class="pay">
            <div class="left">
              <img src="../../assets/img/icon/11.png" alt="" />
            </div>
            <div class="right">
              <div class="tips">
                <span>订单提交成功！去付款咯～</span>
              </div>
              <span class="total"
                >应付金额: <span class="num">{{ cost.price }}</span
                >元
              </span>
              <div class="line"></div>
              <div class="_info">
                <div class="info-title">订单信息：</div>
                <div class="d-flex">
                  <div class="mr-10">
                    <div class="group">
                      <div class="label">订单号：</div>
                      <div class="value">{{ order.orderId }}</div>
                    </div>
                   
                    <div class="group">
                      <div class="label">会议金额：</div>
                      <div class="value">
                        <span v-if="cost.productId == 1"> (2000)在职代表 </span>
                        <span v-if="cost.productId == 2"> (1500)在校学生 </span>
                       
                      </div>
                    </div>
                    <!-- <div class="group">
                      <div class="label">单位/公司名称：</div>
                      <div class="value">
                        {{ cost.companyName }}
                      </div>
                    </div> -->
                    <!-- <div class="group">
                      <div class="label">统一社会信用代码</div>
                      <div class="value">{{ cost.socialCreditCode }}</div>
                    </div> -->
                    <!-- <div class="group">
                      <div class="label">获取方式：</div>
                      <div class="value">
                        <span v-if="cost.sendType == 1"> 电子邮箱 </span>
                        <span v-if="cost.sendType == 2"> 会场自取 </span>
                        <span v-if="cost.sendType == 3"> 快递邮寄 </span>
                      </div>
                    </div> -->
                  </div>
                  <!-- 
                  <div v-if="cost.sendType == 1">
                
                    <div class="group">
                      <div class="label">电子邮箱：</div>
                      <div class="value">{{ cost.mailbox }}</div>
                    </div>
                   
                 
                  </div>
                     <div v-if="cost.sendType == 3">
               
                    <div class="group">
                      <div class="label">邮寄地址：</div>
                      <div class="value">{{ cost.address }}</div>
                    </div>
                    <div class="group">
                      <div class="label">联系人姓名：</div>
                      <div class="value">
                        {{ cost.contactName }}
                      </div>
                    </div>
                    <div class="group">
                      <div class="label">联系人手机号：</div>
                      <div class="value">{{ cost.contactPhone }}</div>
                    </div>
                    
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="pay-content">
            <div class="left">
              <img src="../../assets/img/weinxin-pay.png" alt="" />
            </div>
            <div class="right">
              <div class="pay-content-title">微信支付</div>
              <div class="code">
                <div id="qrcode" ref="qrcode"></div>
              </div>
              <div class="pay-footer">
                <p>请使用 <span>微信</span>扫一扫</p>
                <p>二维码完成支付</p>
              </div>
            </div>
          </div>

          <v-btn
            style="min-width: 0 !important; width: 300px; margin: 0 auto"
            color="success"
            block
            class="mt-4"
            @click="goAction"
          >
            已支付
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      money: "",
      order: {},
      status: 0,
      timer: null,
    };
  },

  created() {
    this.cost = JSON.parse(this.$route.query.cost);
    this.order = JSON.parse(this.$route.query.order);

    this.creatQrCode(this.order);
    this.timer = setInterval(() => {
      this.getOrder();
      if (this.status != 0) {
        this.$router.push({
          path: "paySuccess",
          query: { order: this.order.orderId },
        });
        clearInterval(this.timer);
        this.timer = null;
      }
    }, 1000);
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    formStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 100%; margin: auto";
        case "sm":
          return "width: 50%; margin: auto";
        case "md":
          return "width: 60%; margin: auto";
        case "lg":
          return "width: 40%; margin: auto";
        case "xl":
          return "width: 40%; margin: auto";
      }
    },
  },
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "tab-item",
    },
  },
  methods: {
    creatQrCode(order) {
      this.$nextTick(function () {
        new QRCode(this.$refs.qrcode, {
          text: order.url,
          width: 264,
          height: 264,
        });
      });
    },
    getOrder() {
      this.$request({
        url: "/order/get",
        method: "post",
        data: { orderId: this.order.orderId },
      }).then((res) => {
        if (res.code == 200) {
          this.status = res.data.payStatus;
        }
      });
    },
    goAction() {
      this.getOrder();
      if (this.status == 0) {
        this.$message.tips("等待支付,请使用微信扫一扫,完成支付");
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="less" scoped>
.order {
  padding: 0 10px;
  padding-bottom: 150px;
  .item-boeder {
    border: 1px solid #c4d5e0;
  }
  .pay {
    width: 800px;
    display: flex;
    margin: 0 auto;
    .left {
      margin-right: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .right {
      .tips {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: bold;
        line-height: 36px;
      }
      .total {
        font-size: 14px;
        font-weight: 400;
        margin-top: 20px;
        .num {
          color: #ff6700;
          font-size: 24px;
        }
      }
      .line {
        height: 1px;
        background: #e0e0e0;
        margin-top: 20px;
      }
      ._info {
        margin-top: 20px;
        .info-title {
          font-size: 20px;
          font-weight: bold;
        }
        .group {
          display: flex;
          margin-top: 10px;
          .label {
            width: 140px;
          }
          .value {
          }
        }
      }
    }
  }
  .pay-content {
    width: 700px;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    .left {
      width: 307px;
      margin-right: 50px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .right {
      margin-top: 50px;
      width: 264px;
      .pay-content-title {
        height: 50px;
        line-height: 50px;
        background-color: #f5f5f5;

        font-size: 18px;
        font-weight: 400;
        color: #424242;
        text-align: center;
        vertical-align: middle;
      }
      .code {
        width: 264px;
        height: 264px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .pay-footer {
        font-size: 14px;
        color: #333;
        text-align: center;
        margin-top: 10px;
        p {
          margin-bottom: 5px;
          span {
            color: #ff6700;
          }
        }
      }
    }
  }
  .required {
    position: relative;
    width: 80px;
    text-align: end;
    &::before {
      content: "*";
      // position: absolute;
      left: 10%;
      top: 30%;
      font-size: 20px;
      color: red;
    }
  }
  ._content {
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
}
</style>
